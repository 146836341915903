import type { PageComponentProps } from '@/features/a-dynamic-page/components/page-component-switch';
import type { TextV1Attributes } from '@/features/text-v1/text-v1-type';

import React from 'react';

import { HtmlTextWrapper } from '@/core/features';
import { useDeviceoutput } from '@/core/features/cookies/use-device-output';
import { bemModule } from '@/core/utils/bem-classname';

import styles from './text-v1.module.scss';

const bem = bemModule(styles);

export type TextV1Props = React.PropsWithChildren<PageComponentProps<TextV1Attributes>>;

// children prop is needed to render the show more button in a group component
export default function TextV1({ attributes, children, groupComponentProps }: TextV1Props) {
    const { displayStyle, htmlText } = attributes;
    const { isDesktopView } = useDeviceoutput();
    const hasContentBox = displayStyle === null && !groupComponentProps?.hasContentBox && !isDesktopView;

    if (hasContentBox) {
        return (
            <div className={'content-box'}>
                <HtmlTextWrapper
                    className={styles.text}
                    htmlText={htmlText}
                />
                {children}
            </div>
        );
    }

    return (
        <div className={bem(styles.textWrapper, { hasDisplayStyle: !!displayStyle })}>
            <HtmlTextWrapper
                className={styles.text}
                htmlText={htmlText}
            />
            {children}
        </div>
    );
}
